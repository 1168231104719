import React, { useState } from 'react';
import { Marker } from 'react-map-gl';
import { useSpring, animated, config } from 'react-spring';
import { Vehicle } from '../types';

interface BusMarkerProps {
    vehicle: Vehicle;
}

const AnimatedMarker = animated(Marker);

const BusMarker: React.FC<BusMarkerProps> = ({ vehicle }) => {
    const [isOpen, setIsOpen] = useState(false);

    const springProps = useSpring({
        to: {
            latitude: vehicle.position.lat,
            longitude: vehicle.position.lng,
            scale: isOpen ? 1.1 : 1,
            opacity: 1
        },
        from: {
            latitude: vehicle.prevPosition.lat,
            longitude: vehicle.prevPosition.lng,
            scale: 0.8,
            opacity: 0.5
        },
        config: { ...config.gentle, duration: 1000 }
    });

    const toggleMarker = () => {
        setIsOpen(!isOpen);
    };

    const getTimeDifference = (arrivalTime: string) => {
        const now = new Date();
        const arrival = new Date(arrivalTime);
        const diffMinutes = Math.round((arrival.getTime() - now.getTime()) / 60000);
        return diffMinutes > 0 ? `${diffMinutes} min` : 'Arrived';
    };

    return (
        <AnimatedMarker
            longitude={springProps.longitude}
            latitude={springProps.latitude}
        >
            <animated.div
                style={{
                    cursor: 'pointer',
                    backgroundColor: vehicle.route.color,
                    color: vehicle.route.text_color,
                    padding: '5px',
                    borderRadius: '5px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    transform: springProps.scale.to(scale => `scale(${scale})`),
                    opacity: springProps.opacity
                }}
                onClick={toggleMarker}
            >
                {isOpen ? (
                    <div>
                        <div>{vehicle.route.short_name}</div>
                        <div>Last: {vehicle.last_stop.name}</div>
                        <div>Next: {vehicle.next_stop.name}</div>
                        <div>In: {getTimeDifference(vehicle.next_stop.arrival_time)}</div>
                    </div>
                ) : (
                    <div>{vehicle.route.short_name}</div>
                )}
            </animated.div>
        </AnimatedMarker>
    );
};

export default BusMarker;