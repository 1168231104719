import React, { useState, useEffect, useCallback } from 'react';
import Map from 'react-map-gl';
import axios from 'axios';
import BusMarker from './components/BusMarker';
import { Vehicle } from './types';
import 'mapbox-gl/dist/mapbox-gl.css';

// Note: In a production environment, it's better to use environment variables
// to manage sensitive information like API tokens.
const MAPBOX_TOKEN = 'pk.eyJ1Ijoic3lzZGV2cnVuIiwiYSI6ImNtMDExYjh6NjF2ZWoyanNkMGVzaHY4M2wifQ.MhaU3P-jBRVIyOcOrc4L6Q';

const App: React.FC = () => {
  const [vehicles, setVehicles] = useState<{ [id: string]: Vehicle }>({});
  const [loading, setLoading] = useState<boolean>(true);

  const updateVehicles = useCallback((newVehicles: Vehicle[]) => {
    setVehicles(prevVehicles => {
      const updatedVehicles: { [id: string]: Vehicle } = {};

      newVehicles.forEach(vehicle => {
        updatedVehicles[vehicle.id] = {
          ...vehicle,
          prevPosition: prevVehicles[vehicle.id]?.position || vehicle.position
        };
      });

      return updatedVehicles;
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://www.bus.re/live/carjaune.json');
        updateVehicles(response.data.vehicles);
      } catch (error) {
        console.error('Error fetching bus data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Initial fetch

    const interval = setInterval(fetchData, 10000); // Update every 10 seconds

    return () => clearInterval(interval);
  }, [updateVehicles]);

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Map
        mapboxAccessToken={MAPBOX_TOKEN}
        initialViewState={{
          longitude: 55.5,
          latitude: -21.1,
          zoom: 10
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
      >
        {Object.values(vehicles).map((vehicle) => (
          <BusMarker key={vehicle.id} vehicle={vehicle} />
        ))}
      </Map>
      {loading && (
        <div style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          backgroundColor: 'white',
          padding: '5px',
          borderRadius: '5px',
          zIndex: 1000
        }}>
          Updating bus positions...
        </div>
      )}
    </div>
  );
};

export default App;